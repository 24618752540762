.badge {
  text-transform: $badge-text-transfom;

  a {
    color: #fff;
  }
}

// Variations
.badge-pill {
  padding-right: $badge-pill-padding-x;
  padding-left: $badge-pill-padding-x;
}

.badge-circle {
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  font-size: 0.875rem;
}

// Multiple inline badges
.badge-inline {
  margin-right: 0.625rem;
}

.badge-inline + span {
  top: 2px;
  position: relative;
}

.badge-inline + span > a {
  text-decoration: underline;
}

// Sizes
.badge-md {
  padding: 0.65em 1em;
}

.badge-lg {
  padding: 0.85em 1.375em;
}

// Color variations

.badge-secondary {
  color: $gray-800;
}

// Link badges

.btn {
  .badge {
    &:not(:first-child) {
      margin-left: 0.5rem;
    }
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

// Price tag badge

.ptag-r {
  position: relative;
  margin: 0 5px 0 10px;
  display: inline-block;
  padding: 0 10px 0 5px;
  font-size: smaller;
  font-weight: bold;
  border: 1px solid $primary;
  border-radius: 5px 0 0 5px;
  color: #fff;
  background-color: $primary;

  &.orange-bg {
    background-color: #f58220;
    border-color: #f58220;
  }

  &.green-bg {
    background-color: #97c225;
    border-color: #97c225;
  }

  &.grey-bg {
    background-color: #777777;
    border-color: #777777;
  }

  &.original {
    background-color: #efefef;
    border-color: #efefef;
  }
}

.ptag-r:before {
  position: absolute;
  content: '';
  right: -16px;
  width: 1px;
  height: 0px;
  border-left-width: 15px;
  border-left-style: solid;
  border-left-color: inherit;
  border-top: 10px solid transparent;
  border-bottom: 11px solid transparent;

  @media only screen and (max-width: 991px) {
    right: -11px;
    border-left-width: 10px;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
  }
}

.ptag-r:after {
  content: '';
  background-color: white;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  display: block;
  position: absolute;
  right: -5px;
  top: 8px;

  @media only screen and (max-width: 991px) {
    top: 7px;
  }
}
