.form {
  .col-term {
    padding: 2rem 3.125rem;
    line-height: 1.875rem;
  }

  .form-control {
    color: #000000;
  }

  .btn-submit {
    color: white;
    background: black;
    float: right;
    cursor: pointer;
    box-shadow: none !important;
  }

  .btn-submit:hover {
    background: #454747;
  }

  .btn-upload {
    color: white;
    background: #02c7b6;
    box-shadow: none !important;
  }

  .btn-upload:hover {
    background: #039689;
  }

  mat-select {
    font-weight: bold;
  }
}
