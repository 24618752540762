// .container-fluid, .container {
// 	padding-left: 1.5rem;
// 	padding-right: 1.5rem;
// }

@include media-breakpoint-up(lg) {
  .container-lg {
    max-width: 1160px;
  }

  // .container-fluid {
  // 	padding-left: 4rem;
  // 	padding-right: 4rem;
  // }
}
