/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.toast-top-center {
  top: 50px;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 50px;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 50px;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 50px;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 50px;
  left: 12px;
}
.toast-top-right {
  top: 50px;
  right: 12px;
}
.toast-bottom-right {
  right: 50px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 50px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}
.toast-message {
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #ffffff;
}
.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}
.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  @apply tw-text-body tw-text-opacity-70;
}
.toast-close-button:hover,
.toast-close-button:focus {
  text-decoration: none;
  cursor: pointer;
  @apply tw-text-body tw-text-opacity-100;
}
/*Additional properties for button version
   iOS requires the button element instead of an anchor tag.
   If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}
.toast-container * {
  box-sizing: border-box;
}
.toast-container .ngx-toastr {
  position: relative;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 5px;
  background-position: 15px 15px;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  @apply tw-border tw-border-divider tw-border-opacity-80 tw-text-black;
}
.toast-container .ngx-toastr:hover {
  @apply tw-cursor-pointer tw-border-divider tw-border-opacity-100;
}
.toast-info {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZT0iIzJkODk4YiIgZmlsbD0ibm9uZSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4NCiAgIDxwYXRoIHN0cm9rZT0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSI+PC9wYXRoPg0KICAgPHBhdGggZD0iTTMgMTJhOSA5IDAgMSAwIDE4IDBhOSA5IDAgMCAwIC0xOCAwIj48L3BhdGg+DQogICA8cGF0aCBkPSJNMTIgOWguMDEiPjwvcGF0aD4NCiAgIDxwYXRoIGQ9Ik0xMSAxMmgxdjRoMSI+PC9wYXRoPg0KPC9zdmc+);
  @apply tw-border-l-4 tw-border-l-info #{!important};
}
.toast-error {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZT0iI2JmMjAyNiIgZmlsbD0ibm9uZSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4NCiAgIDxwYXRoIHN0cm9rZT0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSI+PC9wYXRoPg0KICAgPHBhdGggZD0iTTEyIDl2NCI+PC9wYXRoPg0KICAgPHBhdGggZD0iTTEwLjM2MyAzLjU5MWwtOC4xMDYgMTMuNTM0YTEuOTE0IDEuOTE0IDAgMCAwIDEuNjM2IDIuODcxaDE2LjIxNGExLjkxNCAxLjkxNCAwIDAgMCAxLjYzNiAtMi44N2wtOC4xMDYgLTEzLjUzNmExLjkxNCAxLjkxNCAwIDAgMCAtMy4yNzQgMHoiPjwvcGF0aD4NCiAgIDxwYXRoIGQ9Ik0xMiAxNmguMDEiPjwvcGF0aD4NCjwvc3ZnPg==);
  @apply tw-border-l-4 tw-border-l-primary #{!important};
}
.toast-success {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZT0iIzBhOTk0YSIgZmlsbD0ibm9uZSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4NCiAgIDxwYXRoIHN0cm9rZT0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSI+PC9wYXRoPg0KICAgPHBhdGggZD0iTTEyIDEybS05IDBhOSA5IDAgMSAwIDE4IDBhOSA5IDAgMSAwIC0xOCAwIj48L3BhdGg+DQogICA8cGF0aCBkPSJNOSAxMmwyIDJsNCAtNCI+PC9wYXRoPg0KPC9zdmc+);
  @apply tw-border-l-4 tw-border-l-secondary #{!important};
}
.toast-warning {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZT0iI2UxODQzNyIgZmlsbD0ibm9uZSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4NCiAgIDxwYXRoIHN0cm9rZT0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSI+PC9wYXRoPg0KICAgPHBhdGggZD0iTTMgMTJhOSA5IDAgMSAwIDE4IDBhOSA5IDAgMCAwIC0xOCAwIj48L3BhdGg+DQogICA8cGF0aCBkPSJNMTIgOHY0Ij48L3BhdGg+DQogICA8cGF0aCBkPSJNMTIgMTZoLjAxIj48L3BhdGg+DQo8L3N2Zz4=);
  @apply tw-border-l-4 tw-border-l-warning #{!important};
}
.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.ngx-toastr {
  @apply tw-bg-white;
  pointer-events: auto;
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}
/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
