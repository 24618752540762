@font-face {
  font-family: 'Poppins';
  src: url('/assets/font/poppins/Poppins-Light.ttf');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('/assets/font/poppins/Poppins-Regular.ttf');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('/assets/font/poppins/Poppins-Medium.ttf');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('/assets/font/poppins/Poppins-SemiBold.ttf');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('/assets/font/poppins/Poppins-Bold.ttf');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/font/inter/Inter-Light.ttf');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/font/inter/Inter-Regular.ttf');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/font/inter/Inter-Medium.ttf');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/font/inter/Inter-SemiBold.ttf');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/font/inter/Inter-Bold.ttf');
  font-weight: 700;
  font-display: swap;
}
