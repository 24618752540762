/*!

=========================================================
* Argon Design System Angular - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-angular
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-angular/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Core

@import 'bootstrap/functions';
@import 'custom/functions';

@import 'custom/variables';
@import 'bootstrap/variables';

@import 'bootstrap/mixins';
@import 'custom/mixins';

// Bootstrap components

@import 'bootstrap/root';
@import 'bootstrap/reboot';
@import 'bootstrap/type';
@import 'bootstrap/images';
@import 'bootstrap/code';
@import 'bootstrap/grid';
@import 'bootstrap/tables';
@import 'bootstrap/forms';
@import 'bootstrap/buttons';
@import 'bootstrap/transitions';
@import 'bootstrap/dropdown';
@import 'bootstrap/button-group';
@import 'bootstrap/input-group';
@import 'bootstrap/custom-forms';
@import 'bootstrap/nav';
@import 'bootstrap/navbar';
@import 'bootstrap/card';
@import 'bootstrap/breadcrumb';
@import 'bootstrap/pagination';
@import 'bootstrap/badge';
@import 'bootstrap/jumbotron';
@import 'bootstrap/alert';
@import 'bootstrap/progress';
@import 'bootstrap/media';
@import 'bootstrap/list-group';
@import 'bootstrap/close';
@import 'bootstrap/modal';
@import 'bootstrap/tooltip';
@import 'bootstrap/popover';
@import 'bootstrap/carousel';
@import 'bootstrap/utilities';
@import 'bootstrap/print';

// Custom components

@import 'custom/reboot.scss';
@import 'custom/global.scss';
@import 'custom/utilities.scss';

@import 'custom/accordion.scss';
@import 'custom/alerts.scss';
@import 'custom/avatars.scss';
@import 'custom/badge.scss';
@import 'custom/buttons.scss';
@import 'custom/card.scss';
@import 'custom/carousel.scss';
@import 'custom/close.scss';
@import 'custom/dropdown.scss';
@import 'custom/footer.scss';
@import 'custom/forms.scss';
@import 'custom/grid.scss';
@import 'custom/icons.scss';
@import 'custom/input-group.scss';
@import 'custom/list-group.scss';
@import 'custom/modal.scss';
@import 'custom/nav.scss';
@import 'custom/navbar.scss';
@import 'custom/pagination.scss';
@import 'custom/popover.scss';
@import 'custom/progress.scss';
@import 'custom/section.scss';
@import 'custom/separator.scss';
@import 'custom/type.scss';
@import 'custom/ckeditor5/content-styles.scss';

// Vendor

@import 'custom/vendor/bootstrap-datepicker.scss';
@import 'custom/vendor/headroom.scss';

// Angular diferences
@import 'angular/alerts.scss';
@import 'angular/navbar.scss';
@import 'angular/dropdowns.scss';
@import 'angular/tabs.scss';
@import 'angular/datepicker.scss';
@import 'angular/input-group.scss';
