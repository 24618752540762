// Bootstrap carousel

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 2.2rem !important;
  color: #c00000;
}

.swiper-pagination-bullet-active {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.testimonial-swiper .swiper-pagination-bullet {
  width: 35px;
  height: 7px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.79);
  border-radius: 0%;
  opacity: 0.2;
}

.testimonial-swiper .swiper-pagination-bullet-active {
  background: white !important;
  opacity: 1;
  border: none;
}

.swiper.s-wrapper.swiper-container-horizontal
  > .swiper-pagination
  .swiper-pagination-handle
  .swiper-pagination-bullet.swiper-pagination-bullet-first,
.swiper.s-wrapper.swiper-container-horizontal
  > .swiper-pagination
  .swiper-pagination-handle
  .swiper-pagination-bullet.swiper-pagination-bullet-last {
  margin: 0 !important;
}

.team-carousel {
  .visually-hidden {
    @apply tw-invisible tw-hidden;
  }

  .carousel-control-next,
  .carousel-control-prev {
    @apply tw-w-[50px] tw-border-none;
    cursor: pointer;
    border: none;
    width: 50px;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    filter: invert(1);
  }
}
