@use '@angular/material' as mat;
@import "@angular/material/theming";
@import "swiper/swiper-bundle.min.css";
@import "swiper/swiper.min.css";
@import "./app/shared/styles/template/argon.scss";
@import "./app/shared/styles/app-loading.scss";
@import "./app/shared/styles/table.scss";
@import "./app/shared/styles/form.scss";
@import "./app/shared/services/popup/popup.scss";
@import "./app/shared/styles/color.scss";
@import "./app/shared/styles/font.scss";
@import "ngx-sharebuttons/themes/default/default-theme";
@import "./app/shared/styles/common.scss";
@import "./app/shared/styles/toast.scss";
@import "./app/shared/styles/tour-guide.scss";

@include mat.core();

html, body { height: 100%; }

html {
    font-size: 16px;
    scrollbar-gutter: stable;

    @media only screen and (max-width: 767px) {
        font-size: 14px;
        scrollbar-gutter: auto;
    }
}

body {
    margin: 0;
    background-color: #ffffff;
    color: $default;
    overflow-x: hidden;

    &.modal-open { padding-right: 0 !important }
}

.container-fluid {
    max-width: 1280px;
}

.open-notification-bar .main-container {
    @apply tw-pt-[150px] sm:tw-pt-[110px] md:tw-pt-[130px] lg:tw-pt-[110px] #{!important};
}

.campaign-tabs-body {
    .ck-content .image.image_resized,
    .ck-content figure {
        @apply tw-max-w-full;
    }

    .tw-flex-auto {
        min-width: 0;
    }
}

$custom-typography: mat-typography-config(
    $font-family: $font-family-base,
);

@include mat.all-component-typographies($custom-typography);

.legal-list {
    margin-top: 30px;
}

.legal-list strong {
    font-weight: 700;
}

.legal-list ol {
    counter-reset: item;
    padding-left: 0;
}

.legal-list ol li {
    display: table;
}

.legal-list > ol > li {
    margin-bottom: 10px;
}

.legal-list ol li:before {
    display: table-cell;
    padding-right: 10px;
}

.legal-list ol > li:before {
    counter-increment: item;
    content: counters(item, ".") ". ";
}

.legal-list > ol > li > ol > li:before {
    min-width: 55px;
    text-align: right;

    @media (max-width: 768px) {
        min-width: 40px;
    }
}

.legal-list h2 {
    font-size: 1.5rem;
}

.legal-list li h2 {
    font-size: 1.2rem;
}

/* for alphabet list */
.alpha-list > ol {
    counter-reset: alpha;
}

.alpha-list > ol > li:before {
    counter-increment: alpha;
    content: "(" counter(alpha, lower-alpha) ") ";
}

/* for uppercase alphabetlist */
.upperalpha-list > ol {
    counter-reset: upperAlpha;
}

.upperalpha-list > ol > li:before {
    counter-increment: upperAlpha;
    content: "(" counter(upperAlpha, upper-alpha) ") ";
}

/* for lowerroman list */
.lowerroman-list > ol {
    counter-reset: lowerRoman;
}

.lowerroman-list > ol > li:before {
    counter-increment: lowerRoman;
    content: counter(lowerRoman, lower-roman) ". ";
}

/* for number list */
.num-list > ol {
    counter-reset: numList;
}

.num-list > ol > li:before {
    counter-increment: numList;
    content: "(" counter(numList) ") ";
}

.legal-description-list {
    display: grid;
    grid-template-columns: 30% 70%;
    font-weight: 200;
    gap: 5px 10px;
}

.legal-description-list dt {
    font-weight: 400;
    word-break: break-word;
}

.br {
    @apply tw-block tw-pt-1;
}

.accordion-body ul, ol {
    @apply tw-mb-0;
}

/* tables */
.table {
    &--modern {
        @apply tw-border tw-border-solid tw-border-divider;
    
        th {
            @apply tw-bg-primary tw-text-white;
        }
    
        tr {
            td {
                &:first-child {
                    @apply tw-border-0 tw-border-b tw-border-r tw-border-solid tw-border-divider tw-bg-gray-50;
                }
            }
        }

        th, tr td {
            @apply tw-px-4 tw-py-2.5;
        }
    }
}

.hidden-scrollbar {
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
  }