@use 'sass:math';

.icon {
  width: $icon-size;
  height: $icon-size;

  i,
  svg {
    font-size: $icon-size - 0.75;
  }
}

.icon + .icon-text {
  padding-left: 1rem;
  width: calc(100% - #{$icon-size} - 1);
}

.icon-xl {
  width: $icon-size-xl;
  height: $icon-size-xl;

  i,
  svg {
    font-size: $icon-size-xl - 0.75;
  }
}

.icon-xl + .icon-text {
  width: calc(100% - $icon-size-xl - 1);
}

.icon-lg {
  width: $icon-size-lg;
  height: $icon-size-lg;

  i,
  svg {
    font-size: $icon-size-lg - 0.75;
  }
}

.icon-lg + .icon-text {
  width: calc(100% - $icon-size-lg - 1);
}

.icon-sm {
  width: $icon-size-sm;
  height: $icon-size-sm;

  i,
  svg {
    font-size: $icon-size-sm - 0.75;
  }
}

.icon-sm + .icon-text {
  width: calc(100% - $icon-size-sm - 1);
}

// Icons included in shapes
.icon-shape {
  padding: 12px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  i,
  svg {
    font-size: 1.25rem;
  }

  &.icon-lg {
    i,
    svg {
      font-size: 1.625rem;
    }
  }
  &.icon-sm {
    i,
    svg {
      font-size: 0.875rem;
    }
  }

  svg {
    width: 30px;
    height: 30px;
  }
}

@each $color, $value in $theme-colors {
  .icon-shape-#{$color} {
    @include icon-shape-variant(theme-color($color));
  }
}

@mixin size($width, $height) {
  width: math.div($width, 16) * 1rem;
  height: math.div($width, 16) * 1rem;
}

@mixin pitchInIcon() {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

[class^='pitchin-icon-'],
[class*='pitchin-icon-'] {
  @include pitchInIcon;
}

.pitchin-icon {
  @include size(24, 24);
  @apply tw-inline-block;
}

.pitchin-icon-xs {
  @include size(20, 20);
  @apply tw-inline-block;
}

.pitchin-icon-md {
  @include size(30, 30);
  @apply tw-inline-block;
}

@mixin bgimage($image) {
  background-image: url('/assets/img/' + #{$image});
}

.pitchin-icon-app {
  @include bgimage('brand/pitchin_logo.svg');
}

.pitchin-icon-invest {
  @include bgimage('icons/menu/invest_icon_grey@2x.png');
}

.pitchin-icon-raise {
  @include bgimage('icons/menu/raise_icon_grey@2x.png');
}

.pitchin-icon-representative {
  @include bgimage('icons/common/profile_icon_grey@2x.png');
}

.pitchin-icon-documents {
  @include bgimage('icons/common/document_2_icon_grey@2x.png');
}

.pitchin-icon-details {
  @include bgimage('icons/common/group_icon_grey@2x.png');
}

.pitchin-icon-add {
  @include bgimage('icons/common/add_icon_circle_red@2x.png');
}

.pitchin-icon-profile {
  @include bgimage('icons/common/profile_icon_red_180@2x.png');
}

.pitchin-icon-upload {
  @include bgimage('icons/common/upload_files_icon@2x.png');
}

.pitchin-icon-delete {
  @include bgimage('icons/common/delete_icon@2x.png');
}

.pitchin-icon-company {
  @include bgimage('icons/common/clipboard_icon_grey@2x.png');
}

.pitchin-icon-company-logo {
  @include bgimage('icons/common/add_business_logo_icon@2x.png');
}

.pitchin-icon-notification {
  @include bgimage('icons/menu/bell_icon_black@2x.png');
}

.pitchin-icon-company-profile {
  @include bgimage('icons/common/profile_icon_red@2x.png');
}

.pitchin-icon-coins {
  @include bgimage('icons/common/coins_icon_grey@2x.png');
}

.active {
  .pitchin-icon-invest {
    @include bgimage('icons/menu/invest_icon_red@2x.png');
  }

  .pitchin-icon-raise {
    @include bgimage('icons/menu/raise_icon_red@2x.png');
  }

  .pitchin-icon-documents {
    @include bgimage('icons/common/document_2_icon_red@2x.png');
  }

  .pitchin-icon-company {
    @include bgimage('icons/common/clipboard_icon_red@2x.png');
  }

  .pitchin-icon-coins {
    @include bgimage('icons/common/coins_icon_red@2x.png');
  }

  .pitchin-icon-representative {
    @include bgimage('icons/common/profile_icon_red@2x.png');
  }

  .pitchin-icon-details {
    @include bgimage('icons/common/group_icon_red@2x.png');
  }

  .pitchin-icon-wallet {
    @include bgimage('icons/common/icon_wallet@2x.png');
  }
}

.pitchin-icon-check-grey {
  @include bgimage('icons/common/check_icon_grey@2x.png');
}

.pitchin-icon-check-green {
  @include bgimage('icons/common/check_icon_green@2x.png');
}

.pitchin-icon-edit {
  @include bgimage('icons/common/edit_icon_white@2x.png');
}

.pitchin-icon-shield-white {
  @include bgimage('icons/common/shield_icon_white@2x.png');
}

.pitchin-icon-shield-red {
  @include bgimage('icons/common/shield_icon_red@2x.png');
}

.pitchin-icon-shield-green {
  @include bgimage('icons/common/shield_icon_green@2x.png');
}

.pitchin-icon-next-arrow {
  @include bgimage('icons/common/right_arrow_icon_red@2x.png');
}

.pitchin-icon-next-arrow-green {
  @include bgimage('icons/common/right_arrow_icon_green@2x.png');
}

.pitchin-icon-up-arrow {
  @include bgimage('icons/common/up_arrow_icon_red@2x.png');
}

.pitchin-icon-clock-ongoing {
  @include bgimage('icons/common/clock_ongoing_icon@2x.png');
}

.pitchin-icon-invest-ecf {
  @include bgimage('icons/common/invest-ecf.png');
}

.pitchin-icon-invest-pstx {
  @include bgimage('logo/logo_pstx_square.png');
}

.pitchin-icon-invest-ieo {
  @include bgimage('icons/common/invest-ieo.png');
}

.pitchin-icon-invest-shariah {
  @include bgimage('icons/common/icons8-mosque-66.png');
}

.pitchin-icon-learn-learning-portal {
  @include bgimage('icons/common/learn-learning-portal.png');
}

.pitchin-icon-learn-faq {
  @include bgimage('icons/common/learn-faq.png');
}

.pitchin-icon-learn-academy {
  @include bgimage('icons/common/learn-academy.png');
}

.logo-icon-alpha-red {
  @include bgimage('logo/logo_alpha_red.png');
}

.logo-icon-apex {
  @include bgimage('logo/logo_apex.png');
}

.logo-icon-coffeeprint {
  @include bgimage('logo/logo_coffeeprint.png');
}

.logo-icon-spurr {
  @include bgimage('logo/logo_spurr.png');
}

.pitchin-icon-add-red {
  @include bgimage('icons/common/add_icon_red.png');
}

.pitchin-icon-add-grey-transparent {
  @include bgimage('icons/common/add_icon_grey@2x.png');
}

.pitchin-icon-view-more-red {
  @include bgimage('icons/common/icon_view_more_red@2x.png');
}

.pitchin-icon-add-red-transparent {
  @include bgimage('icons/common/add_icon_red@2x.png');
}

.pitchin-icon-minus-red {
  @include bgimage('icons/common/minus_icon_red.png');
}

.pitchin-icon-investor {
  @include bgimage('icons/common/iconinvestor-black@2x.png');
}

.pitchin-icon-minimum {
  @include bgimage('icons/common/icon-up-arrow-black@2x.png');
}

.pitchin-icon-valuation {
  @include bgimage('icons/common/icon-diamond-black@2x.png');
}

.pitchin-icon-edit-black {
  @include bgimage('icons/common/edit_icon_black@2x.png');
}

.pitchin-icon-pitchin-logo-white {
  @include bgimage('brand/pitchin_logo_white.svg');
}

.pitchin-icon-facebook {
  @include bgimage('icons/common/facebook_icon@2x.png');
}

.pitchin-icon-linkedin {
  @include bgimage('icons/common/linkedin_icon@2x.png');
}

.pitchin-icon-tiktok {
  @include bgimage('icons/common/tiktok_icon@2x.png');
}

.pitchin-icon-filter {
  @include bgimage('icons/common/icon-filter@2x.png');
}

.pitchin-icon-search {
  @include bgimage('icons/common/search_icon_grey@2x.png');
  @apply tw-h-5 tw-w-5;
}

.pitchin-icon-star {
  @include bgimage('icons/common/star_icon_black@2x.png');
}

.pitchin-icon-yellow-star {
  @include bgimage('icons/common/star_icon_yellow@2x.png');
}

.pitchin-icon-share {
  @include bgimage('icons/common/share_icon_black@2x.png');
}

.pitchin-icon-whatsapp {
  @include bgimage('icons/common/whatsapp_icon_circle_2@2x.png');
}

.pitchin-icon-facebook {
  @include bgimage('icons/common/facebook_icon_circle_2@2x.png');
}

.pitchin-icon-instagram {
  @include bgimage('icons/common/instagram_icon_circle_2@2x.png');
}

.pitchin-icon-linkedin {
  @include bgimage('icons/common/linkedin_icon_circle_2@2x.png');
}

.pitchin-icon-tiktok {
  @include bgimage('icons/common/tiktok_icon_circle@2x.png');
}

.pitchin-icon-twitter {
  @include bgimage('icons/common/x_icon_round.png');
}

.pitchin-icon-document {
  @include bgimage('icons/common/document_3_icon_grey@2x.png');
}

.pitchin-icon-document-red {
  @include bgimage('icons/common/icon-documents@2x.png');
}

.pitchin-icon-apostrophe-1 {
  @include bgimage('icons/common/apostrophe_icon_grey_1@2x.png');
}

.pitchin-icon-apostrophe-2 {
  @include bgimage('icons/common/apostrophe_icon_grey_2@2x.png');
}

.pitchin-icon-bell-icon-blue {
  @include bgimage('icons/common/bell_icon_blue@2x.png');
}

.pitchin-icon-bell-icon-red {
  @include bgimage('icons/menu/bell_icon_red@2x.png');
}

.pitchin-icon-check-icon-red {
  @include bgimage('icons/common/check_icon_red.png');
}

.pitchin-icon-warning_icon_white {
  @include bgimage('icons/common/warning_icon_white@2x.png');
}

.pitchin-icon-warning_icon_red {
  @include bgimage('icons/common/warning_icon_red@2x.png');
}

.pitchin-icon-calendar {
  @include bgimage('icons/common/calendar_icon@2x.png');
}

.pitchin-icon-clock {
  @include bgimage('icons/common/clock_icon@2x.png');
}

.pitchin-icon-info-icon {
  @include bgimage('icons/common/info_icon.png');
}

.pitchin-icon-info-icon-grey {
  @include bgimage('icons/common/info_icon_grey@2x.png');
}

.pitchin-icon-info-icon-green {
  @include bgimage('icons/common/info_icon_green@2x.png');
}

.pitchin-icon-info-icon-red {
  @include bgimage('icons/common/info_icon_red@2x.png');
}

.pitchin-icon-help-icon-green {
  @include bgimage('icons/common/help_icon_green@2x.png');
}

.pitchin-icon-help-icon-red {
  @include bgimage('icons/common/help_icon_red@2x.png');
}

.pitchin-icon-more {
  @include bgimage('icons/common/more_grey_icon@2x.png');
  opacity: 0.4;
}

.pitchin-icon-more-horizontal {
  @include bgimage('icons/common/more_grey_icon_h@2x.png');
  opacity: 0.4;
}

.pitchin-icon-arrow-down-red {
  @include bgimage('icons/common/arrow_down_icon_red@2x.png');
}

.pitchin-icon-arrow-up-green {
  @include bgimage('icons/common/arrow_up_icon_green@2x.png');
}

.pitchin-icon-arrow-down-black {
  @include bgimage('icons/common/arrow_down_black@2x.png');
}

.pitchin-icon-rejected-icon-red {
  @include bgimage('icons/common/rejected_icon_red@2x.png');
}

.pitchin-icon-email-icon-grey {
  @include bgimage('icons/common/email_icon_grey@2x.png');
}

.pitchin-icon-phone-icon-grey {
  @include bgimage('icons/common/phone_icon_grey@2x.png');
}

.pitchin-icon-fpx {
  @include bgimage('icons/common/fpx-1.png');
}

.pitchin-icon-duitnow {
  @include bgimage('icons/common/duitnow.png');
}

.pitchin-icon-blockchain {
  @include bgimage('icons/common/blockchain-icon.png');
}

.pitchin-icon-close {
  @include bgimage('icons/common/close_icon_circle@2x.png');
}

.pitchin-icon-add-grey {
  @include bgimage('icons/common/add_icon_circle_grey@2x.png');
}

.pitchin-icon-wallet {
  @include bgimage('icons/common/wallet_icon_grey@2x.png');
}
.pitchin-icon-download {
  @include bgimage('icons/common/icon-download.svg');
}

.pitchin-icon-info-grey-small {
  @include bgimage('icons/common/info_icon_grey_small@2x.png');
}

.pitchin-icon-caution {
  @include bgimage('icons/common/caution_icon@2x.png');
}

.pitchin-icon-credit {
  @include bgimage('icons/common/credit_icon_green@2x.png');
}

.pitchin-icon-debit {
  @include bgimage('icons/common/debit_icon_red@2x.png');
}

.pitchin-icon-withdraw {
  @include bgimage('icons/common/withdraw_icon_circle_red@2x.png');
}

.pitchin-icon-deposit {
  @include bgimage('icons/common/deposit_icon_circle_green@2x.png');
}

.pitchin-icon-withdraw-arrow {
  @include bgimage('icons/svgs/withdraw.svg');
}

.pitchin-icon-deposit-arrow {
  @include bgimage('icons/svgs/deposit.svg');
}

.fa-eye-slash,
.fa-eye {
  cursor: pointer;
}

.pitchin-icon-edit-red {
  @include bgimage('icons/common/icon_edit@2x.png');
}

.pitchin-icon-delete-red {
  @include bgimage('icons/common/icon_delete@2x.png');
}

.pitchin-icon-rewards {
  @include bgimage('icons/common/rewards_icon.png');
}

.pitchin-icon-shariah {
  @include bgimage('icons/common/shariah_icon.svg');
}

.pitchin-icon-why-invest-diversify {
  @include bgimage('icons/common/why-invest-diversify.svg');
}

.pitchin-icon-why-invest-fire {
  @include bgimage('icons/common/why-invest-fire.svg');
}

.pitchin-icon-why-invest-hand-good {
  @include bgimage('icons/common/why-invest-hand-good.svg');
}

.pitchin-icon-why-invest-hand-high5 {
  @include bgimage('icons/common/why-invest-hand-high5.svg');
}

.pitchin-icon-why-invest-hand-ok {
  @include bgimage('icons/common/why-invest-hand-ok.svg');
}

.pitchin-icon-why-invest-low-investment {
  @include bgimage('icons/common/why-invest-low-investment.svg');
}

.pitchin-icon-why-invest-report {
  @include bgimage('icons/common/why-invest-report.svg');
}

.pitchin-icon-why-invest-screening {
  @include bgimage('icons/common/why-invest-screening.svg');
}

.pitchin-icon-why-invest-due-diligence {
  @include bgimage('icons/common/why-invest-due-diligence.svg');
}

.pitchin-icon-why-invest-campaign-live {
  @include bgimage('icons/common/why-invest-campaign-live.svg');
}

.pitchin-icon-invest-ecf {
  @include bgimage('icons/common/invest-ecf.png');
}

.pitchin-icon-question-cirle-small {
  @include bgimage('icons/common/icon_question_circle.svg');
}

.pitchin-icon-arrow-left {
  @include bgimage('icons/common/arrow-left.svg');
}

.pitchin-icon-arrow-right {
  @include bgimage('icons/common/arrow-right.svg');
}

.pitchin-icon-help-circle {
  @include bgimage('icons/common/help-circle.svg');
}

.pitchin-icon-help-circle-light {
  @include bgimage('icons/common/help-circle-white.svg');
}

.pitchin-icon-increase-arrow {
  @include bgimage('icons/common/arrow-increase.svg');
}

.pitchin-icon-decrease-arrow {
  @include bgimage('icons/common/arrow-decrease.svg');
}

.pitchin-icon-flat-arrow {
  @include bgimage('icons/common/arrow-flat.svg');
}

.pitchin-icon-filter-lines {
  @include bgimage(('icons/common/filter-lines.svg'));
}

.pitchin-icon-left-arrow {
  @include bgimage(('icons/common/icon_arrow-left.svg'));
}

.pitchin-icon-clear {
  @include bgimage('icons/common/clear.svg');
}

.pitchin-icon-info-circle {
  @include bgimage('icons/svgs/info-circle.svg');
}
