.progress-wrapper {
  position: relative;
  padding-top: 1.5rem;
}
.progress {
  height: 8px;
  margin-bottom: $spacer;
  overflow: hidden;
  border-radius: $border-radius-sm;
  background-color: $progress-bg;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.progress .sr-only {
  width: auto;
  height: 20px;
  margin: 0 0 0 30px;
  left: 0;
  clip: auto;
  line-height: 20px;
  font-size: 13px;
}

.progress-heading {
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 2px;
  padding: 0;
}
.progress-bar {
  box-shadow: none;
  border-radius: 0;
  height: auto;
}
.progress-info {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.progress-label {
  span {
    display: inline-block;
    color: $primary;
    font-size: 0.625rem;
    font-weight: 600;
    text-transform: uppercase;
    background: rgba($primary, 0.1);
    padding: 0.25rem 1rem;
    border-radius: 30px;
  }
}

.progress-percentage {
  text-align: right;

  span {
    display: inline-block;
    color: $gray-600;
    font-size: 0.875rem;
    font-weight: 600;
  }
}

.mat-mdc-progress-bar {
  height: 10px !important;
  border-radius: 10px;

  .mdc-linear-progress__buffer-bar {
    background-color: #efefef;
  }

  .mdc-linear-progress__bar-inner {
    border-color: #e18437;
    border-top-width: 12px;
    border-radius: 0 5px 5px 0;
  }

  &.mat-success {
    .mdc-linear-progress__buffer-bar {
      background-color: #dcf4e9;
    }

    .mdc-linear-progress__bar-inner {
      border-color: #006937;
    }
  }
}

.campaign-details.mat-mdc-progress-bar {
  height: 2.5rem !important;
  border-radius: 5px;

  .mdc-linear-progress__bar-inner {
    border-top-width: 40px;
  }
}
