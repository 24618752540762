.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-panel {
  background: #fff;
}

.mat-select-panel:not([class*='mat-elevation-z']) {
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  white-space: wrap;
  max-width: 100%;
  .mat-option-text {
    width: 100px !important;
  }
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #3f51b5;
}

.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ff4081;
}

.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}

.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

/* Form controls */
.form-control {
  font-size: $input-btn-font-size;
  border-color: #d8d8d8;
  color: $black;
  height: calc(2.5rem + 2px);
  font-weight: 500;

  &:focus {
    color: $black;

    &::placeholder {
      color: $input-focus-placeholder-color;
    }
  }

  &.mat-chip-list {
    height: unset;
  }
}

/* Textareas */
textarea[resize='none'] {
  resize: none !important;
}

textarea[resize='both'] {
  resize: both !important;
}

textarea[resize='vertical'] {
  resize: vertical !important;
}

textarea[resize='horizontal'] {
  resize: horizontal !important;
}

/* Alternative styles */

.form-control-muted {
  background-color: $input-muted-bg;
  border-color: $input-muted-bg;
  box-shadow: none;

  &:focus {
    background-color: $input-muted-bg;
    //box-shadow: none;
  }
}

.form-control-alternative {
  box-shadow: $input-alternative-box-shadow;
  border: 0;
  transition: box-shadow 0.15s ease;

  &:focus {
    box-shadow: $input-focus-alternative-box-shadow;
  }
}

// Sizes

.form-control-lg {
  font-size: $font-size-base;
}

// Validation

.has-success,
.has-danger {
  position: relative;

  &:after {
    width: 19px;
    height: 19px;
    line-height: 19px;
    text-align: center;
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 2px;
    transform: translateY(50%);
    border-radius: 50%;
    font-size: 9px;
    opacity: 1;
  }
}

.has-success {
  &:after {
    content: '\ea26';
    color: daken($form-feedback-valid-color, 18%);
    background-color: $form-feedback-valid-bg;
  }
  .form-control {
    background-color: $input-focus-bg;

    &:focus {
      border-color: $input-focus-border-color;
    }

    // Placeholder
    &::placeholder {
      color: $form-feedback-valid-color;
    }
  }

  &.focused {
    &:after {
      //opacity: 0;
    }
  }
}

.has-danger {
  &:after {
    content: '\ea53';
    color: daken($form-feedback-invalid-color, 18%);
    background-color: $form-feedback-invalid-bg;
  }
  .form-control {
    background-color: $input-focus-bg;

    &:focus {
      border-color: $input-focus-border-color;
    }

    // Placeholder
    &::placeholder {
      color: $form-feedback-invalid-color;
    }
  }

  &.focused {
    &:after {
      //opacity: 0;
    }
  }
}

.password-panel {
  position: relative;

  input {
    padding-right: 3rem;
  }

  .show-password {
    width: 2.5rem;
    position: absolute;
    right: 0;
    top: 6px;
    color: #6a6a6a;

    .fa {
      vertical-align: middle;
    }
  }

  .password-hint {
    display: block;
    padding: 1rem;

    &:after {
      background: #fff;
      box-shadow: none;
      content: '';
      display: block;
      height: 16px;
      width: 16px;
      left: 20px;
      position: absolute;
      bottom: 100%;
      transform: rotate(-45deg) translateY(1rem);
      z-index: -5;
      border-radius: 0.2rem;
    }
  }
}

.show-form-error {
  input,
  .mat-select,
  textarea {
    &.ng-invalid {
      border-color: $red !important;
    }
  }

  angular-editor.ng-invalid {
    .angular-editor-textarea {
      border-color: $red !important;
    }
  }

  .mat-radio-group.ng-invalid .mat-radio-outer-circle,
  .mat-selection-list.ng-invalid .mat-pseudo-checkbox {
    border: 1px solid $red !important;
  }

  signature-pad {
    &.ng-invalid {
      canvas {
        border-color: $red !important;
      }
    }
  }

  mat-checkbox {
    &.ng-invalid {
      .mat-checkbox-frame {
        border-color: $red !important;
      }
    }
  }

  .ck-editor {
    &.ng-invalid {
      .ck-editor__editable {
        border-color: $red !important;
      }
    }
  }
}

.manual-set-error.error {
  border-color: $danger !important;
}

.error {
  color: $red !important;
  text-align: left;
  font-size: 0.9rem;
}

.profile-upload {
  width: 7rem;
  height: 7rem;
  margin: auto;
  position: relative;

  .pitchin-icon {
    width: 2rem;
    height: 2rem;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid #c00000;
  }

  .btn-danger {
    padding: 0.1rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    position: absolute;
    right: 0rem;
    bottom: 0;
  }
}

.square-upload {
  .profile-image {
    border-radius: 5px;
    border: none;
    box-shadow: 0 3px 3px 0 rgb(0 0 0 / 20%);
  }
}

.document-upload {
  background-color: #edf5ff;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  color: #005dd1;
  font-size: 0.9rem;
  font-weight: 600;
  display: flex;
  gap: 5px;

  &.uploaded {
    background-color: #ffeded;
    color: #c00000;
  }

  .document-text {
    width: 100%;
    word-break: break-all;
    align-self: center;
  }

  .pitchin-icon {
    width: 1.2rem;
    height: 1.2rem;
    align-self: center;
  }

  &.disabled {
    @apply tw-cursor-not-allowed tw-bg-gray-200 tw-text-white;

    .pitchin-icon {
      @apply tw-opacity-20 tw-grayscale tw-filter;
    }
  }
}

.banner-upload {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  margin: 0;

  .upload-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .upload-field {
    border: 2px solid #005dd1;
    border-radius: 5px;
    background-color: #edf5ff;
    color: #005dd1;
    font-size: 0.9rem;
    font-weight: 600;

    @media only screen and (max-width: 414px) {
      font-size: 0.6rem;
    }
  }

  .pitchin-icon {
    width: 3rem;
    height: 3rem;
    margin: auto;
  }

  .drop-document {
    text-align: center;
    padding-top: 1rem;
  }

  .upload-document-instruction {
    text-align: center;
    padding-top: 1.5rem;
    font-style: italic;
    color: #6a6a6a;
    font-weight: 500;

    @media only screen and (max-width: 414px) {
      padding-top: 0.5rem;
    }
  }

  .banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: 0 3px 3px 0 rgb(0 0 0 / 20%);
  }

  .pitchin-icon-add {
    width: 2rem;
    height: 2rem;
    position: absolute;
    right: -0.5rem;
    top: -0.5rem;
    transform: rotate(45deg);
  }
}

.mat-pseudo-checkbox {
  border: 1px solid #d8d8d8 !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  border-radius: 5px !important;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: $primary !important;

  &:after {
    top: 5px;
    left: 5px;
    width: 10px;
    height: 5px;

    @media only screen and (max-width: 767px) {
      top: 4px;
      left: 3px;
    }
  }
}

.mat-list-base {
  @apply tw-space-y-1.5 tw-pt-0 #{!important};
}

.mat-list-base .mat-list-item .mat-list-item-content,
.mat-list-base .mat-list-option .mat-list-item-content {
  padding: 0 16px 0 0 !important;
}

.mat-list-base .mat-list-item,
.mat-list-base .mat-list-option {
  height: auto !important;
  @apply tw-py-1;
}

.mat-checkbox-inner-container {
  width: 1.2rem !important;
  height: 1.2rem !important;
  border-radius: 10px !important;
  margin-right: 0.5rem !important;
}

.mat-checkbox-frame {
  border: 1px solid #bebebe !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background: #29b61f;
}

.mat-radio-outer-circle {
  border-color: #d8d8d8;
}

.mat-radio-group {
  @apply tw-space-y-2 tw-font-normal tw-text-body;
  font-size: $input-btn-font-size;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $primary;
  border-width: 10px;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ffffff;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #29b61f;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(#29b61f, 0.3);
}

.mat-radio-label {
  margin-right: 1rem;
  margin-bottom: 0;
}

.input-label {
  margin-bottom: 0.375rem;
  font-weight: 500;
  color: #000000;
  font-size: $input-btn-font-size;
}

.input-description {
  font-size: 0.8rem;
  opacity: 0.6;
  margin-bottom: 1rem;
  margin-top: -0.5rem;
}

.input-desc {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  opacity: 0.6;
}

.input-panel {
  padding-bottom: 1.5rem;
}

.mat-select-trigger {
  height: unset !important;
}

.date-input {
  position: relative;

  .form-control {
    padding-left: 3.35rem;
  }

  .mat-datepicker-toggle,
  .owl-date-time-toggle {
    font-size: 1rem;
    color: $primary;
    position: absolute;
    left: 0.2rem;
    top: 0;

    .mdc-icon-button {
      width: 42px;
      height: 42px;
    }

    .pitchin-icon-calendar,
    .pitchin-icon-clock {
      margin: auto;
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  .owl-date-time-toggle {
    top: 0.5rem;
  }
}

.mat-slide-toggle-bar {
  height: 1.3rem !important;
  width: 3rem !important;
  background-color: #e6e6e6;
  border-radius: 10px !important;
}

.mat-slide-toggle-thumb {
  height: 1.85rem !important;
  width: 1.85rem !important;
  background-color: #b1b1b1;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(1.5rem, 0, 0) !important;
}

.mat-slide-toggle-thumb-container {
  width: 1.75rem !important;
  height: 1.75rem !important;
  top: -0.25rem !important;
  margin-left: -0.1rem !important;
}

.mat-checkbox {
  font-size: $input-btn-font-size;

  + .mat-checkbox {
    @apply tw-mt-2;
  }
}

.mat-checkbox-layout {
  white-space: normal !important;
  margin-bottom: 0;
  font-size: $input-btn-font-size;
}

.mat-radio-label-content {
  color: $black;
  font-weight: 500;
}

.mat-select-arrow {
  color: $primary;
}

.phone-input-field {
  position: relative;

  .mat-select {
    width: 6rem;
    border-radius: 5px 0 0 5px;
    border-right: none;
    position: absolute;
    left: 1px;
    border: none;
    top: 1px;
    height: 2.5rem;
  }

  input {
    border-radius: 5px;
    padding-left: 6rem;
  }
}

signature-pad {
  canvas {
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    height: 10rem;
  }
}

input.mat-chip-input {
  border-color: transparent;

  &:focus {
    outline: none;
  }
}

.ck {
  font-family: 'Inter', sans-serif !important;

  &.ck-editor__editable {
    min-height: 10rem;
    color: #000000;
  }
}

.ck-content {
  color: #000000;

  a[target='_blank'] {
    word-break: break-all;
  }
}

.header_pitchIN,
.ck.ck-button.header_pitchIN {
  color: #000000;
  font-size: 3.125rem;
  font-weight: 900;

  @media only screen and (max-width: 767px) {
    font-size: 2rem;
  }
}

.sub_header_pitchIN,
.ck.ck-button.sub_header_pitchIN {
  color: #000000;
  font-size: 1.5rem;
  font-weight: 700;
}

.ck p,
.ck.ck-button.ck-heading_paragraph,
.ck-content p,
.ck-content.ck-button.ck-heading_paragraph {
  @apply tw-mb-8 tw-text-lg tw-leading-normal tw-text-black;
}

.mat-select {
  &.mat-select-disabled {
    background-color: #e9ecef;

    .mat-select-value {
      color: #000000;
    }
  }
}

.tooltip-inner {
  white-space: pre-line !important;
  text-align: left;
}

.cdk-overlay-container {
  z-index: 99999 !important;
}

fieldset {
  border: 1px solid #e0e0e0;
  padding: 1rem 1rem;
  width: 100%;
  border-radius: 10px;

  legend {
    width: auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 0;
    border-radius: 5px;
    color: #000;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.long-text-option {
  &.mat-option {
    height: fit-content !important;
    padding: 0.8rem 1rem;
    line-height: unset !important;
  }

  .mat-option-text {
    white-space: normal;
  }
}

.mdc-icon-button {
  padding: 0 !important;
}

.mat-standard-chip {
  background: #e0e0e0;
}

.custom-mat-icon svg {
  vertical-align: unset !important;
}

.asterisk {
  &:after {
    content: ' *';
    @apply tw-text-primary;
  }
}

.invisible-select {
  @apply tw-appearance-none tw-rounded tw-border-0 tw-bg-transparent tw-px-0.5 tw-py-1 tw-text-base;

  &__wrapper {
    @apply tw-flex tw-items-center;

    &::after {
      @apply tw-inline-block tw-pl-2 tw-opacity-50 tw-content-["\f0d7"];
      font: normal normal normal 14px / 1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
    }
  }
}
