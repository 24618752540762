.modal-content {
  border: 0;
  @if $enable-rounded {
    border-radius: $modal-content-border-radius;
  } @else {
    border-radius: 0;
  }
}

.modal-title {
  @apply tw-text-2xl tw-font-bold tw-text-black;
}

.modal-body {
  .modal-header + & {
    @apply tw-pt-0;
  }
}

.modal-fluid {
  .modal-dialog {
    margin-top: 0;
    margin-bottom: 0;
  }
  .modal-content {
    border-radius: 0;
  }
}

.modal-wrapper {
  &--md .modal-dialog {
    @apply tw-max-w-[650px];
  }
}

@each $color, $value in $theme-colors {
  .modal-#{$color} {
    @include modal-variant($value);
  }
}

@include media-breakpoint-up(sm) {
  .window-modal {
    .modal-80 {
      max-width: 80%;
    }
  }
}
