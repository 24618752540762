body.driver-active {
  height: 100%;
  overflow: hidden;
}
.driver-active .driver-overlay,
.driver-active * {
  pointer-events: none;
}
.driver-active .driver-active-element,
.driver-active .driver-active-element * {
  @apply tw-pointer-events-none tw-select-none;
}
.driver-popover,
.driver-popover * {
  pointer-events: auto;
}
@keyframes animate-fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.driver-fade .driver-overlay {
  animation: animate-fade-in 0.2s ease-in-out;
}
.driver-fade .driver-popover {
  animation: animate-fade-in 0.2s;
}
.driver-popover {
  all: unset;
  box-sizing: border-box;
  margin: 0 auto;
  width: 400px;
  max-width: 95%;
  box-shadow: 0 1px 10px #0006;
  z-index: 1000000000;
  position: fixed;
  @apply tw-rounded-10px tw-bg-primary tw-p-5 tw-text-white max-sm:tw-bottom-0 max-sm:tw-left-0 lg:tw-p-8;
}
.driver-popover-title {
  @apply tw-pr-5 tw-text-xl tw-font-bold;
}
.driver-popover-description {
  @apply tw-text-center tw-text-sm tw-text-white;
}
.driver-popover-description a {
  @apply tw-font-bold tw-text-white tw-underline hover:tw-text-opacity-80;
}
.driver-popover-close-btn {
  all: unset;
  position: absolute;
  width: 32px;
  height: 32px;
  cursor: pointer;
  font-weight: 500;
  z-index: 1;
  text-align: center;
  @apply tw-right-[10px] tw-top-[10px] tw-text-2xl tw-text-white tw-text-opacity-50 hover:tw-text-opacity-100 lg:tw-right-[25px] lg:tw-top-[25px];
}
.driver-popover-title[style*='block'] + .driver-popover-description {
  @apply tw-mt-8;
}
.driver-popover-footer {
  text-align: right;
  zoom: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @apply tw-mt-10;
}
.driver-popover-footer button,
.tg-ghost-btn {
  all: unset;
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;
  cursor: pointer;
  outline: 0;
  zoom: 1;
  @apply tw-max-w-[60%] tw-flex-1 tw-rounded tw-bg-white tw-bg-opacity-100 tw-px-4 tw-py-1.5 tw-text-center tw-text-sm tw-font-bold tw-text-black hover:tw-bg-opacity-80;
}
.tg-ghost-btn {
  @apply tw-mt-2.5 tw-w-full tw-max-w-full tw-border-2 tw-border-solid tw-border-white tw-bg-transparent tw-text-white hover:tw-border-opacity-80 hover:tw-text-white;
}
.driver-popover-footer .driver-popover-prev-btn {
  @apply tw-bg-gray-400 tw-text-white;
}
.driver-popover-footer .driver-popover-btn-disabled {
  pointer-events: none;
  @apply tw-bg-white tw-bg-opacity-50 tw-text-black tw-text-opacity-50;
}
:not(body):has(> .driver-active-element) {
  overflow: hidden !important;
}
.driver-no-interaction,
.driver-no-interaction * {
  pointer-events: none !important;
}
.driver-popover-navigation-btns {
  @apply tw-flex tw-w-full tw-justify-center tw-gap-2.5;
}
.driver-popover-arrow {
  content: '';
  position: absolute;
  border: 5px solid;
  @apply tw-border-primary;
}
.driver-popover-arrow-side-over {
  display: none;
}
.driver-popover-arrow-side-left {
  left: 100%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-top-color: transparent;
}
.driver-popover-arrow-side-right {
  right: 100%;
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-top-color: transparent;
}
.driver-popover-arrow-side-top {
  top: 100%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.driver-popover-arrow-side-bottom {
  bottom: 100%;
  border-left-color: transparent;
  border-top-color: transparent;
  border-right-color: transparent;
}
.driver-popover-arrow-side-center {
  display: none;
}
.driver-popover-arrow-side-left.driver-popover-arrow-align-start,
.driver-popover-arrow-side-right.driver-popover-arrow-align-start {
  top: 15px;
}
.driver-popover-arrow-side-top.driver-popover-arrow-align-start,
.driver-popover-arrow-side-bottom.driver-popover-arrow-align-start {
  left: 15px;
}
.driver-popover-arrow-align-end.driver-popover-arrow-side-left,
.driver-popover-arrow-align-end.driver-popover-arrow-side-right {
  bottom: 15px;
}
.driver-popover-arrow-side-top.driver-popover-arrow-align-end,
.driver-popover-arrow-side-bottom.driver-popover-arrow-align-end {
  right: 15px;
}
.driver-popover-arrow-side-left.driver-popover-arrow-align-center,
.driver-popover-arrow-side-right.driver-popover-arrow-align-center {
  top: 50%;
  margin-top: -5px;
}
.driver-popover-arrow-side-top.driver-popover-arrow-align-center,
.driver-popover-arrow-side-bottom.driver-popover-arrow-align-center {
  left: 50%;
  margin-left: -5px;
}
.driver-popover-arrow-none {
  display: none;
}
.driver-popover--first {
  .driver-popover-prev-btn.driver-popover-btn-disabled {
    @apply tw-pointer-events-auto tw-bg-gray-400 tw-bg-opacity-100 tw-text-white tw-text-opacity-100 hover:tw-bg-opacity-80;
  }
}
