.mat-header-cell {
  color: $default;
  font-weight: 600;
  text-transform: uppercase;
  overflow: visible;

  button {
    text-transform: uppercase;
  }
}

.pagination {
  font-weight: 700;
}

.mysec-pagination {
  width: 100%;

  .pagination {
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  ul {
    margin-top: 1rem;
    padding: 0 1rem 0 1rem;
  }

  .page-item {
    .page-link {
      box-shadow: none;
      border: none;
      border-radius: 8px !important;
      line-height: 25px;
      width: 2rem;
      height: 2rem;
      font-size: 0.9rem;
      margin-right: 0.5rem;
      padding: 1rem;
    }

    &.active {
      .page-link {
        color: #4eac5f;
        background-color: #c2f0ca !important;
        border-radius: 8px;
        border: none;
      }
    }

    &:hover {
      background: none;
    }

    &:first-child {
      width: 100%;
      margin-left: 0.5rem;

      .page-link {
        width: 7.5rem;
        border: 1px solid #b1b1b1;
      }
    }

    &:last-child {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .page-link {
        width: 5.625rem;
        border: 1px solid #b1b1b1;
      }
    }
  }
}

.page-item {
  .page-link {
    box-shadow: none;
    color: $default;
    line-height: 25px;
    border: 1px solid;
    border-radius: 4px !important;
    width: 2rem;
    height: 2rem;
    font-size: 0.9rem;
    margin-right: 0.5rem;
    @apply tw-border-table;
  }

  &.active {
    .page-link {
      color: #ffffff;
      background-color: #000000 !important;
      box-shadow: none;
      border-color: #000000;
    }
  }

  &:hover {
    background: none;
  }
}

.mobile-pagination {
  .page-link {
    @apply tw-border-none;
  }

  .pagination {
    @apply tw-flex tw-justify-between;
  }

  .page-item {
    @apply first:tw-pt-2 last:tw-pt-2;
  }
}

.mat-table {
  border-bottom: 1px solid #f0f0f0;

  td {
    display: table-cell;
    vertical-align: middle;
    border: 0.5px solid #e0e0e0;
  }
}

.mat-header-row {
  min-height: 2rem;
  background-color: #e3e3e3;
}

.mat-row {
  min-height: 2.6rem;
  border-bottom: none;
}

.mat-cell {
  align-self: stretch;
  color: $default;

  .btn {
    border-radius: 4px;
    font-size: 0.8rem;
    height: unset;
    padding: 0.25rem 1rem;
  }

  .link {
    cursor: pointer;
    color: #016532;
    font-weight: 600;
  }
}

.mat-cell,
.mat-header-cell {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.paging {
  float: right;
  width: auto;

  @media only screen and (max-width: 767px) {
    float: unset;
    display: flex;
    justify-content: center;
  }
}

.no-data {
  text-align: center;
  padding: 1rem;
}

@media (max-width: 991px) {
  .mattable-container {
    overflow-x: scroll;
  }

  .responsive-table {
    width: 1024px;
  }
}

.mysec-table {
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  border-top: none;

  @media only screen and (max-width: 991px) {
    border-top: 1px solid #d8d8d8;
  }

  .mat-table {
    margin-top: 1rem;
    border-top: 1px solid #d8d8d8;
    border-radius: 8px 8px 0 0;
    border-bottom: none;

    @media only screen and (max-width: 991px) {
      border-top: none;
      margin-top: unset;
    }
  }

  .mat-header-row {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    border-bottom: 1px solid#D8D8D8;
    background-color: transparent;
  }

  .mat-row {
    border-bottom: 1px solid #d8d8d8;

    &:last-child {
      border-bottom: none;
    }
  }

  .mat-header-cell {
    font-size: 0.875rem;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.5);
  }

  .mat-cell {
    font-size: 0.875rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5);
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
  }
}

.mysec-mini-table {
  overflow: hidden;

  .mat-table {
    border-bottom: none;
  }

  .mat-header-cell {
    font-size: 0.75rem;
    text-transform: capitalize;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    @apply tw-text-body;
  }

  .mat-header-row {
    background: transparent;
  }

  .mat-cell {
    font-size: 0.75rem;
    color: #000000;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }

  .mat-row {
    min-height: 2rem;
  }

  mat-cell:first-of-type,
  mat-header-cell:first-of-type,
  mat-footer-cell:first-of-type {
    padding-left: 1rem;
  }

  mat-cell:last-of-type,
  mat-header-cell:last-of-type,
  mat-footer-cell:last-of-type {
    padding-right: 1rem;
  }
}

.mat-tooltip {
  word-break: break-word !important;
  text-overflow: unset !important;
  overflow: unset !important;
}
