@import 'utilities/backgrounds.scss';
@import 'utilities/floating.scss';
@import 'utilities/helper.scss';
@import 'utilities/position.scss';
@import 'utilities/sizing.scss';
@import 'utilities/spacing.scss';
@import 'utilities/shadows.scss';
@import 'utilities/text.scss';
@import 'utilities/transform.scss';

.font-weight-extrabold {
  font-weight: 800;
}

.cursor-pointer {
  cursor: pointer;
}

.font-size-regular {
  font-size: 1rem !important;
}

.font-size-larger {
  font-size: 1.125rem !important;
}

.font-size-large {
  font-size: 1.25rem !important;
}

.font-size-smaller {
  font-size: 0.875rem !important;
}

.font-size-small {
  font-size: 0.75rem !important;
}

.text-black {
  color: $black;
}

.text-white {
  color: $white;
}

.text-green {
  color: #006837;
}

.text-light-grey {
  color: #a3a3a3;
}

.text-break-all {
  word-break: break-all;
}

.text-break {
  word-break: break-word;
}

.category-badge {
  background-color: #efefef;
  border-radius: 5px;
  color: #818181;
  padding: 0.2rem 0.9rem;
  font-size: 0.75rem;
  margin-right: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.status-badge {
  padding: 0.2rem 0.8rem;
  border-radius: 15px;
  font-size: 1rem;
  font-weight: 700;
  width: fit-content;
}

.success-background {
  background-color: #e2fff0;
  color: #006837;
}

.tab-number {
  background-color: #ffeded;
  color: #c00000;
  font-weight: 600;
  align-self: center;
  margin-left: 0.2rem;
  font-size: 0.875rem;
  padding: 0.1rem 0.5rem;
}

.router-link {
  color: inherit;

  &:hover {
    color: inherit;
  }
}

.m-fadeOut {
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0s linear 300ms,
    opacity 300ms;
}

.m-fadeIn {
  visibility: visible;
  opacity: 1;
  transition:
    visibility 0s linear 0s,
    opacity 300ms;
}

.line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;

  -webkit-box-orient: vertical;
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
}

.logo-image {
  width: 5rem;
  height: 5rem;
  border-radius: 5px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.152);
  object-fit: cover;
  background-color: #fff;
  overflow: hidden;
}

@media (max-width: 768px) {
  .sm-px-0 {
    padding: 0 !important;
  }
}
