.headroom {
  will-change: transform;
  background-color: inherit;
  @include transition($transition-base);
}
.headroom--pinned {
  transform: translateY(0%);
}
.headroom--unpinned {
  transform: translateY(-100%);
}

.headroom--not-top {
  box-shadow: 0 1px 10px rgba(130, 130, 134, 0.1);
}
