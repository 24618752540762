.nav-link {
  color: $nav-link-color;
  cursor: pointer;

  &:hover {
    color: theme-color('primary');
  }

  i:not(.fa) {
    position: relative;
    top: 2px;
  }
}

// Pills
.nav-pills {
  gap: 1rem;

  // .nav-item:not(:last-child) {
  //     padding-right: $nav-pills-space-x;
  // }

  .nav-link {
    padding: $nav-pills-padding-y $nav-pills-padding-x;
    color: $nav-pills-link-color;
    font-weight: 800;
    font-size: $font-size-base;
    // box-shadow: $nav-pills-box-shadow;
    background-color: $nav-pills-bg;
    transition: $transition-base;

    &:hover {
      background-color: #f1f1f1;
      color: $nav-pills-link-hover-color;
    }
  }

  .nav-link.active,
  .show > .nav-link {
    color: $nav-pills-link-active-color;
    background-color: $nav-pills-link-active-bg;
  }

  // @include media-breakpoint-down(xs) {
  //     .nav-item {
  //         margin-bottom: $spacer;
  //     }
  // }
}

// @include media-breakpoint-down(sm) {
//     .nav-pills:not(.nav-pills-circle) {
//         .nav-item {
//             padding-right: 0;
//         }
//     }
// }

// Nav pills circle

.nav-pills-circle {
  .nav-link {
    text-align: center;
    height: 60px;
    width: 60px;
    padding: 0;
    line-height: 60px;
    border-radius: 50%;
  }

  .nav-link-icon {
    i,
    svg {
      font-size: 1rem;
    }
  }
}

// Nav wrapper
.nav-wrapper {
  padding: 1rem 0;
  @include border-top-radius($card-border-radius);

  + .card {
    @include border-top-radius(0);
    @include border-bottom-radius($card-border-radius);
  }
}

// Nav Custom

.navbar-custom.navbar-content {
  border-bottom: none !important;

  .nav-item {
    padding: 0 1.5rem;
    margin-right: 2rem;
    border-radius: 2rem;

    .nav-link {
      padding: 0.5rem 0;
      font-weight: 600;
      color: #b4b4b4;
    }

    &.active {
      background-color: $primary;
      border-bottom: none !important;

      .nav-link {
        color: white;
      }
    }
  }
}

.navbar--lite {
  @apply tw-overflow-hidden tw-p-0 tw-text-sm;

  .navbar__nav {
    @apply tw-ml-0 tw-w-full tw-list-none tw-space-y-1 tw-pl-0;
  }

  .navbar__nav .navbar__nav {
    @apply tw-mt-2.5;

    .nav__link {
      @apply tw-p-2;
    }
  }

  .navbar__nav--parent {
    > .nav__item {
      @apply tw-mb-12;
    }

    > .nav__item > .nav__link {
      @apply tw-font-bold tw-text-black hover:tw-bg-transparent;
    }
  }

  .nav__link {
    @apply tw-rounded-md tw-px-2 tw-py-1 tw-font-medium tw-text-black tw-transition-colors hover:tw-bg-divider hover:tw-bg-opacity-50;

    &.active {
      @apply tw-bg-divider tw-bg-opacity-60;
    }
  }
}
